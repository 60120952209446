.ant-space-item span{
    color:blue;
    cursor: pointer;
}

.ant-switch-checked {
    background-color: #41d722;
}

.loadingpsin{
    margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  border-radius: 4px;
}

.ant-btn-primary {
    color: #fff !important;
    background: #1890ff !important;
    border-color: #1890ff !important;
    text-shadow: 0 -1px 0 rgba(0,0,0,.12) !important;
    box-shadow: 0 2px #0000000b !important;
}

.paddme{
    margin-right: 30px
}
.ant-spin-dot-item{
  background-color: #1167a7;
}
.buttonNew{
    float: right;
margin: 20px;
}

.rdw-editor-wrapper{
    background-color: #eaeaea;
}
@primary-color: #e3f2fd;