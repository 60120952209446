.handCursor {
  cursor: pointer;
}
.langstyle {
  padding-right: 10px;
  font-size: 12px;
}

.langactive {
  color: #00b493;
}

@primary-color: #e3f2fd;